/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import { useStatePersist } from 'use-state-persist';

import Header from '@accrosoft-ltd/vf-careers-site-theme/src/components/header';
import Footer from '@accrosoft-ltd/vf-careers-site-theme/src/components/footer';
import Cookies from '@accrosoft-ltd/vf-careers-site-theme/src/components/cookies';
import SEO from '@accrosoft-ltd/vf-careers-site-theme/src/components/seo';

//import "./base.css"
//import "./bootstrap.min.css"
import '@accrosoft-ltd/vf-careers-site-theme/src/components/addon.min.css';
import '@accrosoft-ltd/vf-careers-site-theme/src/components/vf-addon.css';
import '@accrosoft-ltd/vf-careers-site-theme/src/components/theme.css';

import '@fortawesome/fontawesome-pro/css/all.css';

import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useCookies } from 'react-cookie';

import {
  useStateWithSessionStorage,
  createSignalsSession,
} from '@accrosoft-ltd/vf-careers-site-theme/src/components/analytics';

import { v4 as uuidv4 } from 'uuid';

const Layout = (props) => {
  const [cookies, setCookie] = useCookies(['ap-signals-user-id']);
  const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
    'ap-signals-session-id'
  );
  const [fingerPrintResult, setFingerPrintResult] = useStateWithSessionStorage(
    'ap-signals-session-fingerprint'
  );

  useEffect(() => {
    (async () => {
      if (!cookies['ap-signals-user-id']) {
        // We recommend to call `load` at application startup.
        const fp = await FingerprintJS.load();

        // The FingerprintJS agent is ready.
        // Get a visitor identifier when you'd like to.
        const result = await fp.get();

        // This is the visitor identifier:
        const visitorId = result.visitorId;

        setCookie('ap-signals-user-id', visitorId);
        setFingerPrintResult(JSON.stringify(result));
      } else if (!sessionIDValue) {
        setSessionIDValue(`${uuidv4()}`);
      }

      createSignalsSession(
        cookies['ap-signals-user-id'],
        sessionIDValue,
        props.siteConfig,
        props.location,
        fingerPrintResult
      );
    })();
  }, [cookies['ap-signals-user-id'], sessionIDValue]);

  const siteConfig = props.siteConfig || {};

  const [fontSize, setFontSize] = useStatePersist('@fontSize', 'normal');
  const [colourContrast, setColourContrast] = useStatePersist(
    '@colourContrast',
    false
  );

  let iFrameView = false;
  let HideCareersLink = false;

  if (props.location && props.location.search) {
    let searchParams = new URLSearchParams(props.location.search);
    let entries = searchParams.entries();

    for (let pair of entries) {
      if (pair[0] === 'iframe') {
        iFrameView = pair[1];
      }
      if (pair[0] === 'HideCareersLink') {
        HideCareersLink = pair[1];
      }
    }
  }

  return (
    <div
      className={`main-app-wrap page-id-15 careers template-subhomepage app-data index-data singular-data page-data page-15-data page-careers-data colour-contrast-${colourContrast} font-size-${fontSize} iframe-view-${iFrameView} hide-careers-link-${HideCareersLink}`}
    >
      <SEO
        siteConfig={props.siteConfig}
        apiKey={props.apiKey}
        groupOrIdParam={props.groupOrIdParam}
        smartfeedCompanyData={props.smartfeedCompanyData}
        vacanciesRouteLoading={props.vacanciesRouteLoading}
        vacanciesRouteError={props.vacanciesRouteError}
        title={props.title}
        description={props.description}
      />
      <Header
        location={props.location}
        siteConfig={props.siteConfig || {}}
        vacanciesRouteError={props.vacanciesRouteError}
        vacanciesRouteLoading={props.vacanciesRouteLoading}
        apiKey={siteConfig.apiKey || props.apiKey}
        groupOrIdParam={props.groupOrIdParam}
        smartfeedCompanyData={
          siteConfig.smartfeedCompanyData || props.smartfeedCompanyData
        }
        appGatewayURL={props.appGatewayURL}
        setFontSize={setFontSize}
        colourContrast={colourContrast}
        setColourContrast={setColourContrast}
        trackEvent={props.trackEvent}
        alwaysDisplayHeader={props.alwaysDisplayHeader}
      ></Header>
      <div className="app-layout">
        <main id="main">{props.children}</main>
      </div>
      <div className="footer-layout">
        <Footer
          displayJACTA={props.displayJACTA || true}
          siteConfig={props.siteConfig || {}}
          vacanciesRouteError={props.vacanciesRouteError}
          vacanciesRouteLoading={props.vacanciesRouteLoading}
          apiKey={siteConfig.apiKey || props.apiKey}
          groupOrIdParam={props.groupOrIdParam}
          smartfeedCompanyData={
            siteConfig.smartfeedCompanyData || props.smartfeedCompanyData
          }
          appGatewayURL={props.appGatewayURL}
          setFontSize={setFontSize}
          colourContrast={colourContrast}
          setColourContrast={setColourContrast}
          jobAlertsPage={props.jobAlertsPage}
          trackEvent={props.trackEvent}
        />
        <Cookies
          siteConfig={props.siteConfig || {}}
          vacanciesRouteError={props.vacanciesRouteError}
          vacanciesRouteLoading={props.vacanciesRouteLoading}
          apiKey={siteConfig.apiKey || props.apiKey}
          groupOrIdParam={props.groupOrIdParam}
          smartfeedCompanyData={
            siteConfig.smartfeedCompanyData || props.smartfeedCompanyData
          }
          trackEvent={props.trackEvent}
        />
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
