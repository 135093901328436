import React, { useState, useEffect } from 'react';

import { graphql, Link, useStaticQuery, navigate } from 'gatsby';

import Helmet from 'react-helmet';

import '@fortawesome/fontawesome-pro/css/all.css';

import Dropdown from '@accrosoft-ltd/accro-multi-level-dropdown';

const base64url = require('base64url');

const RenderChildLinkHTML = ({ child, i }) => {
  return (
    <Dropdown.Item
      className="dropdown-item link"
      key={i}
      onClick={() =>
        navigate(child.parentSlug ? child.parentSlug + child.slug : child.slug)
      }
    >
      {child.children && <span className="fas fa-angle-right"></span>}
      <div>
        <span>{child.title}</span>
      </div>

      {child.children && child.children.length > 0 && (
        <Dropdown.Submenu
          menuClassName="dropdown-submenu"
          className="dropdown-submenu"
        >
          {child.children.map((childtwo, itwo) => (
            <React.Fragment key={i + '_' + itwo}>
              <RenderChildLinkHTML child={childtwo} i={itwo} />
            </React.Fragment>
          ))}
        </Dropdown.Submenu>
      )}
    </Dropdown.Item>
  );
};

const RenderChildLinks = ({ item }) => {
  return (
    <>
      {item.children && item.children.length > 0 && (
        <>
          {item.children.map((child, i) => (
            <RenderChildLinkHTML child={child} />
          ))}
        </>
      )}
    </>
  );
};

const ReturnChildNavItems = ({ WebsiteNavData }) => {
  return (
    <>
      {WebsiteNavData && WebsiteNavData.length > 0 ? (
        WebsiteNavData.map((item, i) => (
          <Dropdown
            menuClassName="dropdown-child-nav"
            wrapperClassName="dropdown-wrapper-nav"
            buttonClassName="dropdown-parentLink link-item"
            className="dropdown-child-nav"
            isActive={true}
            title={item.title}
            onClick={() =>
              navigate(
                item.parentSlug ? item.parentSlug + item.slug : item.slug
              )
            }
          >
            <RenderChildLinks item={item} i={i} />
          </Dropdown>
        ))
      ) : (
        <li className="loading-nav"></li>
      )}
    </>
  );
};

const Header = (props) => {
  const params = new URLSearchParams(props.location.search);
  const extraDataEncoded = params ? params.get('ExtraData') : null;
  const extraDataDecoded = extraDataEncoded
    ? base64url.decode(extraDataEncoded)
    : null;

  const extraDataDecodedSplit = extraDataDecoded
    ? extraDataDecoded.split('|')
    : [];

  const [showMobileNav, setShowMobileNav] = useState(false);
  const [websiteNavData, setWebsiteNavData] = useState([]);
  const [websiteNavLoading, setWebsiteNavLoading] = useState(true);

  function shadeColor(color, percent) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
    var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
    var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);

    return '#' + RR + GG + BB;
  }

  const accropressWebsiteNavigation = useStaticQuery(graphql`
    query {
      allAccroPress {
        totalCount
        nodes {
          menu {
            id
            slug
            parentSlug
            title
            order
          }
        }
      }
    }
  `);

  const returnSortedNavigation = async function (websiteNavJSON, fromGQL) {
    if (websiteNavLoading) {
      function GetSortOrder(prop) {
        return function (a, b) {
          if (a[prop] > b[prop]) {
            return 1;
          } else if (a[prop] < b[prop]) {
            return -1;
          }
          return 0;
        };
      }

      function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === '-') {
          sortOrder = -1;
          property = property.substr(1);
        }
        return function (a, b) {
          /* next line works with strings and numbers,
           * and you may want to customize it to your needs
           */
          var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
          return result * sortOrder;
        };
      }

      async function procesItem(item, newArrayOrder) {
        // notice that we can await a function
        // that returns a promise
        if (item.children) {
          const newChildrenOrder = await item.children.sort(
            dynamicSort('order')
          );

          item.children = newChildrenOrder;

          newArrayOrder.push(item);
        } else {
          newArrayOrder.push(item);
        }

        return item;
      }

      async function processArray(array) {
        let newArrayOrder = [];

        for (const item of array) {
          await procesItem(item, newArrayOrder);
        }

        newArrayOrder = await newArrayOrder.sort(dynamicSort('order'));

        return newArrayOrder;
      }

      if (fromGQL) {
        const websiteNavItems = await processArray(
          websiteNavJSON.allAccroPress.nodes[0].menu
        );

        if (websiteNavItems) {
          setWebsiteNavData(websiteNavItems);
        } else {
          setWebsiteNavData(websiteNavJSON.allAccroPress.nodes[0].menu);
        }

        setWebsiteNavLoading(false);
      } else {
        const websiteNavItems = await processArray(websiteNavJSON.menu);

        if (websiteNavItems) {
          setWebsiteNavData(websiteNavItems);
        } else {
          setWebsiteNavData(websiteNavJSON.menu);
        }

        setWebsiteNavLoading(false);
      }
    }
  };

  useEffect(() => {
    if (props.siteConfig.showWebsiteNav) {
      returnSortedNavigation(
        props.navResultData || accropressWebsiteNavigation,
        props.navResultData ? false : true
      );
    }
  }, [websiteNavLoading, props.siteConfig.showWebsiteNav]);

  return (
    <div className="top-header-area">
      <header id="header" className="header background1">
        <div id="scriptsWrap"></div>
        <nav className="nav nav--top layer1" id="top_navigation">
          <a href="https://oaklandcare.com/" className="nav__logo">
            <img
              className="home-logo"
              src="https://cdn1.accropress.com/OaklandCare/Oakland%20Care%20Logo%20White%20RGB.png"
              alt="Oakland Care logo"
            />
          </a>
          <div className="nav__hamburger_wrap">
            <div
              className={`nav_hamburger_wrap__inner ${
                showMobileNav === true ? 'toggled' : ''
              }`}
            >
              <div
                className="nav_hamburger_icon btn"
                onClick={() => setShowMobileNav(!showMobileNav)}
              >
                {!showMobileNav ? (
                  <span className="fas fa-bars"></span>
                ) : (
                  <span className="fas fa-times"></span>
                )}
              </div>
              <div className="nav_hamburger_wrap">
                <a
                  href="https://oaklandcare.com/careers/"
                  className="nav_hamburger_link"
                >
                  Home page
                </a>
                <Link to="/" className="nav_hamburger_link">
                  Job Vacancies
                </Link>
                <Link to="/job-alerts" className="nav_hamburger_link">
                  Job Alerts
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
