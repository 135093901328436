import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Footer = (props) => (
  <footer className="footer">
    <div className="wrapper">
      <div className="logo-grid">
        <div className="logo-grid__inner">
          <div className="columns is-mobile is-multiline is-vcentered is-centered is-12">
            <a
              className="column is-half-mobile is-4-tablet fadein fadein-active logo-grid__link"
              href="https://www.cqc.org.uk/"
              target="_blank"
              rel="noopener"
            >
              <img
                className="quality-care lazyloaded"
                data-src="https://oaklandcare.com/app/themes/oaklandcare/dist/images/cqc-logo_c141e03f.svg"
                src="https://oaklandcare.com/app/themes/oaklandcare/dist/images/cqc-logo_c141e03f.svg"
              />
            </a>
            <a
              className="column is-half-mobile is-4-tablet fadein fadein-active logo-grid__link"
              href="https://www.woodlandtrust.org.uk/"
              target="_blank"
              rel="noopener"
            >
              <img
                className="woodland lazyloaded"
                data-src="https://oaklandcare.com/app/themes/oaklandcare/dist/images/woodland--dark_7107e21b.svg"
                src="https://oaklandcare.com/app/themes/oaklandcare/dist/images/woodland--dark_7107e21b.svg"
              />
            </a>
            <a
              className="column is-half-mobile is-4-tablet fadein fadein-active logo-grid__link"
              href="https://www.thecareworkerscharity.org.uk/"
              target="_blank"
              rel="noopener"
            >
              <img
                className="care-workers lazyloaded"
                data-src="https://oaklandcare.com/app/themes/oaklandcare/dist/images/the-care-workers-charity-logo_bf269e05.svg"
                src="https://oaklandcare.com/app/themes/oaklandcare/dist/images/the-care-workers-charity-logo_bf269e05.svg"
              />
            </a>
            <a
              className="column is-half-mobile is-4-tablet fadein fadein-active logo-grid__link"
              href="http://www.careengland.org.uk/"
              target="_blank"
              rel="noopener"
            >
              <img
                className="care-england lazyloaded"
                data-src="https://oaklandcare.com/app/themes/oaklandcare/dist/images/care-england-logo_aedf21b6.svg"
                src="https://oaklandcare.com/app/themes/oaklandcare/dist/images/care-england-logo_aedf21b6.svg"
              />
            </a>
            <a
              className="column is-half-mobile is-4-tablet fadein fadein-active logo-grid__link"
              href="https://disabilityconfident.campaign.gov.uk/"
              target="_blank"
              rel="noopener"
            >
              <img
                className="disability-confident lazyloaded"
                data-src="https://oaklandcare.com/app/themes/oaklandcare/dist/images/disability-confident-logo-committed_8b71daa8.svg"
                src="https://oaklandcare.com/app/themes/oaklandcare/dist/images/disability-confident-logo-committed_8b71daa8.svg"
              />
            </a>
            <a
              className="column is-half-mobile is-4-tablet fadein fadein-active logo-grid__link"
              href="https://www.top-employers.com/en/companyprofiles/gb/oakland-primecare/"
              target="_blank"
              rel="noopener"
            >
              <img
                className="top-employer lazyloaded"
                data-src="https://oaklandcare.com/app/themes/oaklandcare/dist/images/oakland-care-top-employer-2021_8ae092a5.gif"
                src="https://oaklandcare.com/app/themes/oaklandcare/dist/images/oakland-care-top-employer-2021_8ae092a5.gif"
              />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-main">
        <div className="columns is-multiline">
          <div className="column is-half-tablet is-3-desktop">
            <a
              className="footer-main__link footer-main__link--gold footer-main__link--large"
              href="https://oaklandcare.com/our-homes/"
            >
              Our Homes
            </a>
            <a
              className="footer-main__link footer-main__link--white"
              href="https://oaklandcare.com/our-homes/woodland-grove/"
            >
              Woodland Grove
            </a>
            <a
              className="footer-main__link footer-main__link--white"
              href="https://oaklandcare.com/our-homes/hastings-court/"
            >
              Hastings Court
            </a>
            <a
              className="footer-main__link footer-main__link--white"
              href="https://oaklandcare.com/our-homes/beechwood-grove/"
            >
              Beechwood Grove
            </a>
            <a
              className="footer-main__link footer-main__link--white"
              href="https://oaklandcare.com/our-homes/lambwood-heights/"
            >
              Lambwood Heights
            </a>
            <a
              className="footer-main__link footer-main__link--white"
              href="https://oaklandcare.com/our-homes/elsyng-house-enfield/"
            >
              Elsyng House
            </a>
            <a
              className="footer-main__link footer-main__link--white"
              href="https://oaklandcare.com/our-homes/elmbrook-court-wantage/"
            >
              Elmbrook Court
            </a>
          </div>
          <div className="column is-half-tablet is-3-desktop">
            <a
              className="footer-main__link footer-main__link--gold footer-main__link--large"
              href="https://oaklandcare.com/about/"
            >
              About
            </a>
            <a
              className="footer-main__link footer-main__link--gold footer-main__link--large"
              href="https://oaklandcare.com/care-plans/"
            >
              Care Plans
            </a>
            <a
              className="footer-main__link footer-main__link--gold footer-main__link--large"
              href="https://oaklandcare.com/sitemap/"
            >
              Sitemap
            </a>
            <a
              className="footer-main__link footer-main__link--gold footer-main__link--large"
              href="https://oaklandcare.com/contact/"
            >
              Contact
            </a>
            <a
              className="footer-main__link footer-main__link--gold footer-main__link--large"
              href="https://oaklandcare.com/privacy-policy/"
            >
              Privacy Policy
            </a>
          </div>
          <div className="column is-half-tablet is-3-desktop">
            <a
              className="footer-main__link footer-main__link--gold footer-main__link--large"
              href="mailto:info@oaklandcare.com"
            >
              Email Us
            </a>
            <a
              className="footer-main__link--white footer-main__email"
              href="mailto:info@oaklandcare.com"
            >
              info@oaklandcare.com
            </a>
            <a
              className="footer-main__link footer-main__link--gold footer-main__link--large"
              href="tel:+442030193939"
            >
              Call Us
            </a>
            <a
              className="footer-main__link--white footer-main__tel"
              href="tel:00442030193939"
            >
              0203 019 3939
            </a>
          </div>
          <div className="column is-half-tablet is-3-desktop">
            <a
              className="footer-main__link footer-main__link--gold footer-main__link--large"
              href="https://oaklandcare.com/contact/"
            >
              How to Find Us
            </a>

            <div id="" className="vcard">
              <a className="url fn n" href="https://oaklandcare.com/">
                <span className="given-name"></span>
                <span className="additional-name"></span>
                <span className="family-name"></span>
              </a>
              <div className="org">Oakland Care</div>
              <a className="email" href="mailto:info@oaklandcare.com">
                info@oaklandcare.com
              </a>
              <div className="adr">
                <div className="street-address">
                  Lambwood Heights, 244 Lambourne Road
                </div>
                <span className="locality">Chigwell</span>
                <span className="postal-code">IG7 6HX</span>
              </div>
              <div className="tel">0203 019 3939</div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-ending">
        <div className="columns is-vcentered">
          <div className="column is-paddingless is-narrow">
            <a href="/">
              <img
                className="footer-ending__logo"
                src="https://oaklandcare.com/app/themes/oaklandcare/dist/images/oakland-navbar-logo_4503a1bb.svg"
                alt="Oakland Care logo"
              />
            </a>
          </div>
          <div className="column is-narrow">
            <p className="footer-ending__legal">
              All rights reserved © Oakland 2019
            </p>
          </div>
          <div className="column">
            <p className="footer-ending__social">
              Follow us
              <a
                href="https://www.facebook.com/OaklandCareHomes/"
                target="_blank"
              >
                <i className="fab fa-facebook-f fa-fw fa-2x"></i>
              </a>
              <a href="https://twitter.com/oaklandcare" target="_blank">
                <i className="fab fa-twitter fa-fw fa-2x"></i>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
